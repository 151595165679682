import React, {Fragment, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"

import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    Button
} from "@mui/material"
import Alert from '@mui/material/Alert';
import SyncIcon from '@mui/icons-material/Sync';

// context
import { PatientQueryState, downloadDocumentsAsync, clearError} from './patientQuerySlice'

import { AuthState } from "../auth/authSlice"

import PaperComponent from '../../widgets/PaperComponent';

const DownloadDocuments = ({person}) => {

    const {  error, recentRequest } = useSelector(PatientQueryState)
    const { user } = useSelector(AuthState)
    const dispatch = useDispatch()

    const {id, firstName, lastName} = person

    const initialState = {
        open: false,
    }
    let disableQuery

    const [dialog, setDialog] = useState(initialState)
    const { open } = dialog

    const handleClickOpen = () => { 
        dispatch(clearError())
        setDialog({...dialog, open: true}) }
    const handleClose = (event, reason) => {
        dispatch(clearError())
        setDialog(initialState)
    }
    const handleSubmit = () => {
        dispatch(downloadDocumentsAsync(id))
        setDialog(initialState)
    }

    var dt = new Date()
    dt.setDate(dt.getDate() - 1);
    var date = dt.toISOString();

    if(recentRequest && user.organization.defaultDatasourceId === 'CW'){
        if(date.valueOf() > recentRequest.createdAt.valueOf() && [200,202].includes(recentRequest.status) ){
            disableQuery = true 
        }
    }
    

        return (
            <Fragment>
                <Box display='flex' justifyContent='flex-mid'>
                    <Button
                        variant="contained"
                        startIcon={<SyncIcon/>}
                        size={'small'}
                        disabled={disableQuery}
                        onClick={() => {handleClickOpen()}}>Re-sync Document List</Button>
                </Box>

                <Dialog open={open} id="downloadDocumentsModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'} PaperComponent={PaperComponent}>
                <DialogTitle id='form-dialog-title'>Download Documents</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { error && <Alert severity={'error'}>{error}</Alert> }
                    </DialogContentText>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        <DialogContentText>You are about to redownload the documents for {firstName} {lastName} </DialogContentText>
                        <DialogContentText>This will only redownload existing documents.</DialogContentText>
                        <DialogContentText>This will not search the network for additional documents.</DialogContentText>
                        <DialogContentText>Do you wish to continue?</DialogContentText>
                        </Grid>    
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={'primary'}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color={'primary'}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            </Fragment>
        )

}

export default DownloadDocuments